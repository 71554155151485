import React from 'react';
import { Link, graphql} from 'gatsby';
import Layout from '../components/layout';
import SEO from "../components/seo"
import { Fade } from 'react-awesome-reveal';
import BannerImage from "../assets/images/intro-4.svg"

const folioTemplate = ({
	data: {allWordpressPost},
	pageContext: {
		catId,
		catName,
		catSlug,
		categories,
		humanPageNumber,
		numberOfPages,
	},
	}) => 
	
		<Layout>
		<SEO description="Learn more about some of the amazing digital solutions we have delivered for clients across all industries" title="Case studies" />
		
		<nav aria-label="breadcrumb" class="breadcrumbs-section border-top border-bottom mb-0">
			<div class="container">
				<ol class="breadcrumb mb-0 py-2 px-0">
					<li class="breadcrumb-item"><a href="/">Home</a></li>
					<li class="breadcrumb-item"><a href="/folio">Folio</a></li>
				</ol>
			</div>
		</nav>
		
		<section className="intro-section pt-10 pb-5 pt-lg-26 pb-lg-14">
		  <div className="container">
			 <div className="row align-items-center justify-content-between">
				<div className="col-12 col-md-7 mb-8 mb-md-0">
				   <h1 className="mb-0">Discover how we have partnered with our clients to realise their digital goals</h1>
				</div>
				<div className="col-12 col-md-5 text-center text-md-right d-print-none">
				   <div className="img-holder"> 
					   <img src={BannerImage} alt="" width="426" height="242" />
				   </div>
				</div>
			 </div>
		  </div>
	   </section>
	   <div className="container">
		  <div className="works-section py-6 py-lg-10 border-top">
			 <div className="row align-items-center justify-content-between mb-8 mb-lg-15 viewport-section d-print-none in-viewport">
				<Fade triggerOnce="true" direction="top" className="col-md-6 ">
				   <h2 className="text-blue mb-md-0 fw-700">Our best works</h2>
				</Fade>
			 </div>
			 <div className="load-more-holder mb-8 mb-lg-16 viewport-section in-viewport">
				<div className="article-list position-relative row target-holder mb-8 mb-lg-14 fadeInUp folio wow bounceInUp">
				
				{allWordpressPost.edges.map((post, key) => (
					<Fade triggerOnce="true" direction="top" key={key} className="col-12 col-md-4 col-lg-4 position-relative mb-5 mb-md-0 pb-md-6 post-item" >
						<div className="card">
							<Link className="card-img-top d-block position-relative" to={`/folio/${post.node.slug}`}> <strong className="sr-only">This is a card image link</strong> 
							<img src={`${post.node.acf.image.url.localFile.publicURL}`} alt="" width="" height="" /> </Link>
							<div className="card-body">
								<span className="client-name category d-block fw-500 text-fade-black mb-1" dangerouslySetInnerHTML={{ __html: post.node.title}}/>
								<p className="project-name" dangerouslySetInnerHTML={{ __html: post.node.acf.project_name }}/>
								<h2 className="card-title fw-700 text-blue mb-2 mt-4"><Link to={`/folio/${post.node.slug}`}><span dangerouslySetInnerHTML={{ __html: post.node.acf.tagline}} /></Link></h2>
								<p className="card-text" dangerouslySetInnerHTML={{ __html: post.node.acf.intro }}/>
							</div>
						</div>
					</Fade>
				))}
				</div>
			</div>
		</div>
	</div>
		
	</Layout>
	; 
	export default folioTemplate;
	export const pageQuery = graphql`
		query ($catSlug: String!, $skip: Int!, $limit: Int!) {
			allWordpressPost(
				filter: { acf: {hide_on_case_study_listing_page: {eq: false}}, categories: { elemMatch: {slug: { eq: $catSlug } } } }
				skip: $skip
				limit: $limit
				sort: {fields: menu_order, order: ASC}
			) {
				edges {
					node {
						id
						title
						slug
						acf {
							tagline
							intro
							project_name
							image {
								url {
									localFile {
										publicURL
									}
								}
							}
						}
					}
				}
			}
		}
	`